<template>
  <div class="rawGoldChainPageBox">
    <!-- 介绍内容 -->
    <div class="introduceContent">
      <div class="content">
        <!-- 介绍内容 -->
        <div class="introduce">
          <h3>什么是赊金链</h3>
          <p>
            叁陆玖智慧供应链旗下产品，卖方将货先赊销给买方，基于买方未付款的应收账款，通过保险公司的承保背书，银行基于应收账款和承保背书，提供融资。
          </p>
          <el-popover
            title="联系我们"
            width="140"
            trigger="hover"
            content="业务咨询，您可以直接拨打400-199-9369"
          >
            <div slot="reference" class="consultImmediately">
              立即咨询
            </div>
          </el-popover>
        </div>
        <!-- 图片 -->
        <img
          src="@/assets/fourGoldenTreasuresImg/image/banner4.png"
          alt="banner"
        >
      </div>
    </div>
    <!-- 操作指南 -->
    <div class="instructions">
      <h3 class="title">
        操作指南
      </h3>
      <p class="describe">
        适合于因赊销形成未到期应收账款的卖方企业希望提前回笼资金流动性
      </p>
      <!-- 流程 -->
      <div class="flowBox">
        <img src="@/assets/fourGoldenTreasuresImg/image/flow2.png" alt="flow">
      </div>
    </div>
    <!-- 业务流程 -->
    <div class="businessProcess">
      <h3>业务流程一体化</h3>
      <div class="flowBox">
        <div class="item">
          <img src="@/assets/fourGoldenTreasuresImg/icon/capitalChain1.png" alt="icon">
          <span>在线签约、结算</span>
        </div>
        <div class="item">
          <img src="@/assets/fourGoldenTreasuresImg/icon/capitalChain5.png" alt="icon">
          <span>核心企业在线确权</span>
        </div>
        <div class="item">
          <img src="@/assets/fourGoldenTreasuresImg/icon/capitalChain3.png" alt="icon">
          <span>专业授信审核</span>
        </div>
        <div class="item">
          <img src="@/assets/fourGoldenTreasuresImg/icon/capitalChain4.png" alt="icon">
          <span>智能风险预警</span>
        </div>
        <div class="item">
          <img src="@/assets/fourGoldenTreasuresImg/icon/capitalChain2.png" alt="icon">
          <span>打通融资方、资金方、核心企业</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.rawGoldChainPageBox {
  background-color: #fff;
  .businessProcess{
     display: flex;
     align-items: center;
     justify-content: center;
     flex-direction: column;
      background-size: 100% 100%;
      background-color: #fff;
      padding-bottom: 96px;
      .flowBox{
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #333;
        font-size: 1.6rem;
        font-weight: 400;
        width: 120rem;
        .item{
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          img{
            height: 56px;
            width: 56px;
            margin-bottom: 16px;
          }
        }
      }
      h3{
        color: #000000;
        font-size: 2.8rem;
        font-weight: 500;
        padding: 8rem 0;
      }
    }
  .instructions {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10rem 0 6.4rem;
    background-color: #fafafa;
    .flowBox {
      width: 120rem;
      img{
        width: 100%;
        display: block;
      }
    }
    .describe {
      font-size: 2rem;
      color: #666;
      font-weight: 400;
      padding-bottom: 8rem;
    }
    .title {
      font-weight: 500;
      font-size: 2.8rem;
      color: #000;
      line-height: 4rem;
      padding-bottom: 0.8rem;
    }
  }
  .introduceContent {
    width: 120rem;
    padding: 8rem 0;
    margin: auto;
    .content {
      padding: 2.4rem 2.4rem 2.4rem 5.6rem;
      background-color: rgba(255, 126, 6, 0.04);
      display: flex;
      justify-content: space-between;
      .introduce {
        width: 51.6rem;
        .consultImmediately {
          margin-top: 4rem;
          display: inline-block;
          text-align: center;
          font-size: 1.6rem;
          border-radius: 4px;
          background-color: #ff8f27;
          color: #fff;
          width: 12.8rem;
          height: 3.8rem;
          line-height: 3.8rem;
          cursor: pointer;
        }
        p {
          color: #333;
          font-size: 1.6rem;
          line-height: 2.4rem;
        }
        h3 {
          margin: 4rem 0 1.6rem;
          color: #333;
          font-size: 2.8rem;
          font-weight: 500;
        }
      }
      img {
        height: 30rem;
        width: 53.2rem;
      }
    }
  }
}
</style>
