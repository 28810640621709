<template>
  <div class="fourGoldenTreasuresPageBox">
    <div class="bannerImgBox">
      <!-- 大的背景图 -->
      <img v-if="activeIndex===0" class="bannerImg" src="@/assets/fourGoldenTreasuresImg/image/bg2.png" alt="bg">
      <img v-else-if="activeIndex===1" class="bannerImg" src="@/assets/fourGoldenTreasuresImg/image/bg1.png" alt="bg">
      <img v-else-if="activeIndex===2" class="bannerImg" src="@/assets/fourGoldenTreasuresImg/image/bg4.png" alt="bg">
      <img v-else-if="activeIndex===3" class="bannerImg" src="@/assets/fourGoldenTreasuresImg/image/bg3.png" alt="bg">
      <!-- 上面点击的 -->
      <div class="menuBar">
        <!-- logo -->
        <h2 class="logoImg">
          <router-link :to="{path:'/'}">
            <img :src="$localPicture+'logo/logo_white.png'" alt="logo">
          </router-link>
        </h2>
        <!-- 右边 -->
        <div class="tabBox">
          <div class="tab">
            <span v-for="(item,index) in tabArr" :key="index" :class="[activeIndex===index?'active':'']" @click="(activeIndex=index)">{{ item }}</span>
          </div>
          <img src="@/assets/fourGoldenTreasuresImg/icon/icon0.png" alt="icon">
          <span class="phone">400-199-9369</span>
          <router-link class="homePage" :to="{path:'/'}">
            官网首页
          </router-link>
        </div>
      </div>
    </div>
    <!-- 下面是四大金链的 -->
    <div class="content">
      <RawGoldChain v-if="activeIndex===0" />
      <GoldMiningChain v-else-if="activeIndex===1" />
      <CapitalChain v-else-if="activeIndex===2" />
      <TicketGoldChain v-else-if="activeIndex===3" />
    </div>
    <Footer />
  </div>
</template>

<script>
import RawGoldChain from '@/views/fourGoldenTreasures/components/RawGoldChain'
import GoldMiningChain from '@/views/fourGoldenTreasures/components/GoldMiningChain'
import CapitalChain from '@/views/fourGoldenTreasures/components/CapitalChain'
import TicketGoldChain from '@/views/fourGoldenTreasures/components/TicketGoldChain'
import Footer from '@/components/Footer'
export default {
  components: {
    RawGoldChain,
    CapitalChain,
    GoldMiningChain,
    TicketGoldChain,
    Footer
  },
  data() {
    return {
      activeIndex: 0,
      tabArr: ['生金链', '采金链', '赊金链', '票金链']
    }
  },
  created() {
    if (this.$route.query.type) {
      this.activeIndex = Number(this.$route.query.type)
    }
    const setFontSize = () => {
      const htmlDom = document.getElementsByTagName('html')[0]
      const htmlWidth = (document.documentElement.clientWidth || document.body.clientWidth) / 192
      htmlDom.style.fontSize = `${htmlWidth}px`
    }
    window.onresize = setFontSize
    setFontSize()
  }
}
</script>

<style lang="scss">
.fourGoldenTreasuresPageBox{
  background-color: #fff;
  .bannerImgBox{
    position: relative;
    .menuBar{
      position: absolute;
      top: 0;
      left: calc((100% - 120rem) / 2);
      height: 6.4rem;
      width: 120rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .tabBox{
        display: flex;
        align-items: center;
        color: #fff;
        .tab{
          display: flex;
          align-items: center;
          span{
            display: inline-block;
            color: rgba(255,255,255,.6);
            font-size: 1.8rem;
            padding: .8rem 0;
            border-bottom: .2rem solid rgba(0,0,0,0);
            margin-left: 3.2rem;
            font-weight: 400;
            cursor: pointer;
          }
          .active{
            color: #fff;
            font-weight: 500;
            border-bottom: .2rem solid #fff;
          }
        }
        img{
          height: 3.2rem;
          width: 3.2rem;
          margin-left: 7.8rem;
        }
        .phone{
          margin: 0 1.6rem 0 .7rem;
          font-size: 1.8rem;
        }
        .homePage{
          height: 3.8rem;
          width: 9.6rem;
          line-height: 3.8rem;
          text-align: center;
          display: inline-block;
          background-color: #FF8F27;
          border-radius: .4rem;
          font-size: 1.6rem;
          color: #fff;
        }
      }
      .logoImg{
        width: 14.6rem;
        height: 4rem;
        a,img{
          display: block;
          height: 100%;
          width: 100%;
        }
      }
    }
    .bannerImg{
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
